import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './style.scss';

export default function Culture() {
    return (
        <div className='culture'>
            <div className='description'>
                Competitive Salaries, Health Insurance (self, spouse, 2 children, parents), Sodexo card for tax savings,
                24 days paid-leave, learning and development policy, NPS benefit, and Work from Home perks to setup your
                home office.
            </div>
            <Carousel>
                <Carousel.Item>
                    <img
                        src='https://srec-assets.gumlet.io/company/5ce529dbb8cc8c0029ed7902/careerPage/1599651489412_team-outing.jpg?blur=30'
                        data-src='https://srec-assets.gumlet.io/company/5ce529dbb8cc8c0029ed7902/careerPage/1599651489412_team-outing.jpg'
                        alt={'team outing'}
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        src='https://srec-assets.gumlet.io/company/5ce529dbb8cc8c0029ed7902/careerPage/1601282576289_MVIMG_20200214_150232%20%281%29.jpg?blur=30'
                        data-src='https://srec-assets.gumlet.io/company/5ce529dbb8cc8c0029ed7902/careerPage/1601282576289_MVIMG_20200214_150232%20%281%29.jpg'
                        alt={'sports day'}
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        src='https://srec-assets.gumlet.io/company/5ce529dbb8cc8c0029ed7902/careerPage/1599651461690_springrole-office.jpg?blur=30'
                        data-src='https://srec-assets.gumlet.io/company/5ce529dbb8cc8c0029ed7902/careerPage/1599651461690_springrole-office.jpg'
                        alt={'springrole office'}
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        src='https://srec-assets.gumlet.io/company/5ce529dbb8cc8c0029ed7902/careerPage/1599651512551_springrole-team.jpg?blur=30'
                        data-src='https://srec-assets.gumlet.io/company/5ce529dbb8cc8c0029ed7902/careerPage/1599651512551_springrole-team.jpg'
                        alt={'springrole team'}
                    />
                </Carousel.Item>
            </Carousel>
        </div>
    );
}
