import React from 'react';
import './style.scss';
import Navbar from '../../../components/careers/Nav';

export default function Banner() {
    return (
        <>
            <Navbar />
            <div className='banner'>
                <div className='bannerImg' />
                <div className='content'>
                    <h1>Redefining the future of HR today</h1>
                    <p className='desc'>
                        If you're passionate about making a difference, join us in revolutionising the HR industry
                    </p>
                    <a href={'#all-openings'}>VIEW OPENINGS</a>
                </div>
            </div>
        </>
    );
}
