import React from 'react';
import './style.scss';

function getCells(cells) {
    const res = [];
    for (let i = 1; i < cells.length + 1; i++) {
        let num = cells[i - 1].num && '0' + cells[i - 1].num;
        res.push(
            <div className='cell' key={i}>
                <div className='num'>{num}</div>
                <div className='desc'>
                    <p>{cells[i - 1].title}</p>
                    {cells[i - 1].desc}
                </div>
            </div>
        );
    }
    return res;
}

export default function WorkWithUs() {
    const row1 = [
        {
            num: 1,
            title: 'DO IT AS A TEAM',
            desc:
                'At Springworks, we believe that it’s all about teamwork. If we win, we win as a team and if we fall, we fall as a team.',
        },
        {
            num: 2,
            title: 'EXECUTION IS THE METRIC',
            desc:
                'We understand that ideas are important, but ideas without execution are worthless. We focus on execution as our ‘North-star’ metric.',
        },
        {
            num: 3,
            title: 'OWN YOUR WORK',
            desc:
                'We take the responsibility to get work done. Teams take ownership of their work and we do whatever it takes to get the work done.',
        },
    ];

    const row2 = [
        {
            num: 4,
            title: 'EMPATHY BEGINS AT HOME',
            desc:
                'We work, interact and collaborate with people. We understand the diverse set of perspectives that unique individuals bring to our workplace and we work towards creating an environment filled with trust and appreciation.',
        },
        {
            num: 5,
            title: 'SPEAK YOUR MIND',
            desc:
                "We speak up when we believe something is not right and we demonstrate with the 'why'. Ideas, and not hierarchy, get precedence in decision-making.",
        },
        {
            num: '6',
            title: 'ERR ON THE SIDE OF OVER-COMMUNICATION',
            desc:
                "We are building tools for a future that will be 'Remote'. We like to deliberately over-communicate rather than under-communicate when we are in doubt.",
        },
    ];

    return (
        <div className='work-with-us'>
            <div className='heading'>Who we are at the heart of Springworks</div>
            <div className='rows'>{getCells(row1)}</div>
            <div className='rows'>{getCells(row2)}</div>
        </div>
    );
}
