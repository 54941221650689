import React from 'react';
import Navbar from '../../../components/springworks/Navbar';

export default function Nav() {
    return (
        <div className='careersNavbar'>
            <Navbar />
        </div>
    );
}
