import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import './style.scss';

export default function WhyUs() {
    const whyUs = [
        {
            title: "Learn to your heart's content",
            desc:
                "We promote learning and development in the workplace. Tell us about a course you'd like to take up online & we will fund it for you!",
        },
        {
            title: 'Demonstrate with “Why”',
            desc:
                'We start by demonstrating the ‘Why’ behind any decision at the workplace. We not only demonstrate with a ‘Why’, but also seek a ‘Why’ as opposed to blindly accepting a decision or idea.',
        },
        {
            title: 'Create from the user’s perspective',
            desc:
                "Build products with a team that puts themselves in the user's shoes in every step of the creative and development process.",
        },
        {
            title: 'Be yourself. Be more of who you are.',
            desc:
                "Wear what you like. Work with flexible timings. We won't judge you. We don't just work 9-5. We make a difference.",
        },
    ];
    const status = useScript('https://d31au0f9bp1wfs.cloudfront.net/v1/widget.js');
    return (
        <div className='why-us'>
            {status === 'ready' && (
                <Helmet>
                    <script src='https://d31au0f9bp1wfs.cloudfront.net/v1/widget.js' />
                    <script>
                        {`
                            var access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ODI3NzA0LCJ2ZXJzaW9uIjoxLCJ0eXBlIjoid2lkZ2V0VG9rZW4iLCJpYXQiOjE2MTg1NTYwMzV9.s7juYSgJhCdnsWHjLyBAimunLR4TMhVUIGTLjDHqsm8';
                            var config = {
                                token: access_token,
                                companyId: '5ce529dbb8cc8c0029ed7902',
                                selector: '#widget',
                            };
                            var myWidget = MyLibrary.default.widgets.careerWidget.new(config);
                            myWidget.render({ arg: '00001' });
                        `}
                    </script>
                </Helmet>
            )}
            <div className='heading'>We're rated 4.9/5 on Glassdoor</div>
            <div className='description'>
                We're proud to build a team of 130+ lovely people & achieve a Glassdoor rating of 4.9 with 100+ reviews.
                Employee wellness, engagement & productivity are our priorities at Springworks, and that's why we offer
                perks to our team to set up their very own home office and dedicate a learning & development budget for
                everyone so that we always strive to learn more!
            </div>
            <div className='heading'>Why Springworks</div>
            <div className='rows why'>
                {whyUs.map((w, i) => (
                    <div className='cell' key={i}>
                        <div className='num'>{w.title}</div>
                        <div className='desc'>{w.desc}</div>
                    </div>
                ))}
            </div>
            <div id='widget' />
        </div>
    );
}

function useScript(src) {
    // Keep track of script stats ("idle", "loading", "ready", "error")
    const [status, setStatus] = useState(src ? 'loading' : 'idle');
    useEffect(
        () => {
            // Allow falsy src value if waiting on other data needed for
            // constructing the script URL passed to this hook.
            if (!src) {
                setStatus('idle');
                return;
            }
            // Fetch existing script element by src
            // It may have been added by another instance of this hook
            let script = document.querySelector(`script[src="${src}"]`);
            if (!script) {
                // Create script
                script = document.createElement('script');
                script.src = src;
                script.async = true;
                script.setAttribute('data-stats', 'loading');
                // Add script to document body
                document.body.appendChild(script);
                // Store stats in attribute on script
                // This can be read by other instances of this hook
                const setAttributeFromEvent = (event) => {
                    script.setAttribute('data-stats', event.type === 'load' ? 'ready' : 'error');
                };
                script.addEventListener('load', setAttributeFromEvent);
                script.addEventListener('error', setAttributeFromEvent);
            } else {
                // Grab existing script stats from attribute and set to state.
                setStatus(script.getAttribute('data-stats'));
            }
            // Script event handler to update stats in state
            // Note: Even if the script already exists we still need to add
            // event handlers to update the state for *this* hook instance.
            const setStateFromEvent = (event) => {
                setStatus(event.type === 'load' ? 'ready' : 'error');
            };
            // Add event listeners
            script.addEventListener('load', setStateFromEvent);
            script.addEventListener('error', setStateFromEvent);
            // Remove event listeners on cleanup
            return () => {
                if (script) {
                    script.removeEventListener('load', setStateFromEvent);
                    script.removeEventListener('error', setStateFromEvent);
                }
            };
        },
        [src] // Only re-run effect if script src changes
    );
    return status;
}
