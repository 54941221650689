import React, { Component } from 'react';
import Banner from '../../components/careers/Banner';
import Benefits from '../../components/careers/Benefits';
import Culture from '../../components/careers/Culture';
import WorkWithUs from '../../components/careers/WorkWithUs';
import WhyUs from '../../components/careers/WhyUs';
import Footer from '../../components/springworks/Footer';
import './style.scss';
import { Helmet } from 'react-helmet';

export default class Careers extends Component {
    render() {
        return (
            <div className='careers'>
                <Helmet>
                    <title>Job Openings & Career Opportunities at Springworks</title>
                    <meta
                        data-react-helmet='true'
                        name='description'
                        content='Join us if you are looking for an exciting career opportunity at Springworks. We are Hiring!'
                    />
                    <meta property='og:site_name' content='Springworks' />
                    <meta property='og:title' content='Job Openings & Career Opportunities at Springworks' />
                    <meta
                        property='og:description'
                        content='Join us if you are looking for an exciting career opportunity at Springworks. We are Hiring!'
                    />
                    <meta property='og:url' content='https://springworks.in/careers/' />
                    <meta property='og:type' content='website' />
                    <meta property='og:image' content='https://sw-assets.gumlet.io/careers/Springworks-careers.jpeg' />
                    <meta
                        property='og:image:secure_url'
                        content='https://sw-assets.gumlet.io/careers/Springworks-careers.jpeg'
                    />
                    <meta property='og:image:width' content='1280' />
                    <meta property='og:image:height' content='640' />
                    <meta property='twitter:title' content='Job Openings & Career Opportunities at Springworks' />
                    <meta
                        property='twitter:description'
                        content='Join us if you are looking for an exciting career opportunity at Springworks. We are Hiring!'
                    />
                    <meta property='twitter:card' content='website' />
                    <meta property='twitter:card' content='summary_large_image' />
                    <meta
                        property='twitter:image'
                        content='https://sw-assets.gumlet.io/careers/Springworks-careers.jpeg'
                    />
                    <meta property='twitter:site' content='@springroleinc' />
                    <link data-react-helmet rel='canonical' href='https://www.springworks.in/careers/' />
                </Helmet>
                <Banner />
                <WorkWithUs />
                <Benefits />
                <Culture />
                <WhyUs />
                <Footer />
            </div>
        );
    }
}
