import React from 'react';
import './style.scss';

export default function Benefits() {
    return (
        <div className='benefits'>
            <h2 className='heading'>Benefits (apart from working remotely!)</h2>
            <div className='cards'>
                <div className='fx2 pink'>
                    <img
                        src={'https://sw-assets.gumlet.io/careers/WFH.svg'}
                        alt={'work from home'}
                        data-src={'https://sw-assets.gumlet.io/careers/WFH.svg'}
                    />
                    <p>
                        Work from Home set up - <b>₹50,000</b>
                    </p>
                </div>
                <div>
                    <img
                        src={'https://sw-assets.gumlet.io/careers/HealthInsurance.svg'}
                        data-src={'https://sw-assets.gumlet.io/careers/HealthInsurance.svg'}
                        alt={'health insurance'}
                    />
                    <p>Health Insurance</p>
                </div>
                <div className='light-blue'>
                    <img
                        src={'https://sw-assets.gumlet.io/careers/Sodexo.svg'}
                        data-src={'https://sw-assets.gumlet.io/careers/Sodexo.svg'}
                        alt={'sudexo card'}
                    />
                    <p>Sodexo Card</p>
                </div>
                <div className='sm-light-blue'>
                    <img
                        src={'https://sw-assets.gumlet.io/careers/AnnualPaidLeave.svg'}
                        data-src={'https://sw-assets.gumlet.io/careers/AnnualPaidLeave.svg'}
                        alt={'annual paid leaves'}
                    />
                    <p>
                        <b>24</b> Annual Paid Leave
                    </p>
                </div>
                <div className='light-blue'>
                    <img
                        src={'https://sw-assets.gumlet.io/careers/LifeStyle.svg'}
                        data-src={'https://sw-assets.gumlet.io/careers/LifeStyle.svg'}
                        alt={'fast food'}
                    />
                    <p>
                        Lifestyle Spending
                        <br />
                        Account
                    </p>
                </div>
                <div>
                    <img
                        src={'https://sw-assets.gumlet.io/careers/Learning.svg'}
                        data-src={'https://sw-assets.gumlet.io/careers/Learning.svg'}
                        alt={'Learning'}
                    />
                    <p>
                        Learning and Growth
                        <br /> Budget- <b>$300</b>
                    </p>
                </div>
                <div className='fx2 blue'>
                    <img
                        src={'https://sw-assets.gumlet.io/careers/Internet.svg'}
                        data-src={'https://sw-assets.gumlet.io/careers/Internet.svg'}
                        alt={'internet'}
                    />
                    <p>
                        Internet reimbursement - <b>₹1,500</b>
                    </p>
                </div>
                <div className='sm-pink'>
                    <img
                        src={'https://sw-assets.gumlet.io/careers/Headphones.svg'}
                        data-src={'https://sw-assets.gumlet.io/careers/Headphones.svg'}
                        alt={'headphones'}
                    />
                    <p>
                        Headphone
                        <br />
                        Allowance - <b>₹3,000</b>
                    </p>
                </div>
                <div className='fx2 yellow'>
                    <img
                        src={'https://sw-assets.gumlet.io/careers/workation.svg'}
                        data-src={'https://sw-assets.gumlet.io/careers/workation.svg'}
                        alt={'learning'}
                    />
                    <p>
                        Workation allowance - <b>₹25,000</b>
                    </p>
                </div>
                <div>
                    <img
                        src={'https://sw-assets.gumlet.io/careers/INDWealth.svg'}
                        data-src={'https://sw-assets.gumlet.io/careers/INDWealth.svg'}
                        alt={'rental'}
                    />
                    <p>Subsidized rental</p>
                </div>
                <div className='light-blue'>
                    <img
                        src={'https://sw-assets.gumlet.io/careers/Outings.svg'}
                        data-src={'https://sw-assets.gumlet.io/careers/Outings.svg'}
                        alt={'outings'}
                    />
                    <p>
                        Bi-annual
                        <br />
                        Company outings
                    </p>
                </div>
            </div>
        </div>
    );
}
